(function () {
	'use strict';

	angular
		.module('activation')

		.controller('SubscriptionsCtrl', [
			'$scope',
			'$routeParams',
			'$location',
			'$http',
			'$timeout',
			'seedcodeCalendar',
			'manageSettings',
			'crypt',
			'hash',
			'utilities',
			'environment',
			SubscriptionsCtrl,
		]);

	function SubscriptionsCtrl(
		$scope,
		$routeParams,
		$location,
		$http,
		$timeout,
		seedcodeCalendar,
		manageSettings,
		crypt,
		hash,
		utilities,
		environment
	) {
		var config = seedcodeCalendar.get('config');

		// Set scope variables
		$scope.subscriptionID = $routeParams.subscriptionID;
		$scope.userID = $routeParams.userID;

		// If run after success subscription
		if ($routeParams.subscriptionID && $routeParams.userID) {
			runActivation();
		}
		// If run from activating a child account
		else if ($scope.activation) {
			var primaryMember = ($scope.activation.email = config.account);
			$scope.activation.firstName = config.firstName;
			$scope.activation.lastName = config.lastName;
		}

		// Set scope functions
		$scope.runActivation = runActivation;
		$scope.activateChildSubscription = activateChildSubscription;
		$scope.help = help;
		$scope.close = close;

		function runActivation() {
			var userID = $routeParams.userID;
			var subscriptionID = $routeParams.subscriptionID;

			$scope.activating = true;

			// Activate subscription
			$http({
				method: 'POST',
				url: _CONFIG.DBK_API_BASEURL + 'subscription/activate',
				data: {
					userID: userID,
					subscriptionID: subscriptionID,
				},
			})
				.then(function (response) {
					//Run something after successful post
					$scope.$evalAsync(function () {
						$scope.activated = true;
						$scope.activating = false;
					});

					// try {
					// 	// Check to see if we are inside FileMakerJS
					// 	if (FileMaker && FileMaker.PerformScript) {
					$scope.$evalAsync(function () {
						$location.hash('purchase-success');
					});
					// }
					// } catch (error) {
					// 	// If we aren't in FileMakerJS then we assume browser
					// 	$timeout(function() {
					// 	//Redirect to app.dayback.com
					// 		$location.path('/settings/account/group/');
					// 	}, 1000);
					// }
				})
				.catch(function (error) {
					//Run something on error
					console.log('error', error);
					$scope.$evalAsync(function () {
						$scope.activated = false;
						$scope.activating = false;
					});
				});
		}

		function activateChildSubscription() {
			var config = seedcodeCalendar.get('config');
			var activationCallback = function (error) {
				var errorMessage =
					'There was a problem activating this subscription. ';
				$scope.activation.view.activating = false;
				if (!error) {
					$scope.activation.activationCallback();
				} else if (error) {
					if (error && error.data && error.data.statusMessage) {
						errorMessage += error.data.statusMessage;
					}
					utilities.showModal(
						'Activation Failed',
						errorMessage,
						'OK',
						null,
						null,
						null
					);
				}
			};

			if (
				!$scope.activation.email ||
				!$scope.activation.firstName ||
				!$scope.activation.lastName ||
				!$scope.activation.organization
			) {
				utilities.showMessage(
					'All fields are required. Please fill all fields and try again.',
					0,
					8000,
					'error',
					null,
					true
				);
				return;
			}

			$scope.activation.view.activating = true;

			close();

			// Activate subscription
			$http({
				method: 'POST',
				url: _CONFIG.DBK_API_BASEURL + 'subscription/activate-child',
				data: {
					parentSubscriptionID:
						$scope.activation.parentSubscriptionID,
					parentCustomerID: $scope.activation.parentCustomerID,
					email: $scope.activation.email,
					firstName: $scope.activation.firstName,
					lastName: $scope.activation.lastName,
					organization: $scope.activation.organization,
					quantity: $scope.activation.quantity,
					userID: config.userID,
					groupID: config.groupID,
				},
			})
				.then(function (response) {
					$timeout(function () {
						activationCallback();
					}, 0);

					//Run something after successful post
				})
				.catch(function (error) {
					console.log('error', error);
					$timeout(function () {
						activationCallback(error);
					});
					//Run something on error
				});
		}

		function help(page, pagesf, fullURL, pageShare) {
			utilities.help(page, pagesf, fullURL, pageShare);
		}

		function close(e) {
			//Stop propagation so we don't close popovers behind the modal when clicking close
			if (e) {
				e.stopPropagation();
			}
			$scope.popover.config.show = false;
		}
	}
})();
